<template>
  <div>

    <h4 class="thumbnailsTable__text">{{editAccessoryItem.name}}</h4>
<Form actions="" id="test" @submit="save">
    <div class="row">
      <div class="col-12 col-md-6">
        <label class="form__label">
          <span class="form__labelTitle">Quantity</span>
          <Field type="text" class="form__input"
                 name="quantity"
                 v-model="currentItem.qty"
                 :rules="'required|numeric|min_value:1|max_value:10000'"
          />
        </label>
        <p>
          <ErrorMessage class="form__error form__error_additional"  name="quantity" />
        </p>
      </div>
      <div class="col-12 col-md-6">
        <div class="row align-middle" v-if="currentItem.colour.id">
          <div class="col-6 align-middle">
            <p class="text_default">{{currentItem.colour.colour_name}}</p>
            <p class="text_small" v-if="!currentItem.select_colour">(colour selection not available)</p>
          </div>
          <div class="col-6 align-middle"
               @click="openColourSelection()"
          >
            <div class="colours colour_box colours_small">
            <img class="box__img" :src="currentItem.colour.image" alt="color.colour_name" v-if="currentItem.colour.image">
            <p v-else-if="currentItem.colour.hexcode">
              <svg-icon name="palette" class="large_icon box__img" :style="{color: currentItem.colour.hexcode}"/>
            </p>
            <p v-if="!currentItem.colour.image && !currentItem.colour.hexcode">
              <svg-icon name="palette" class="large_icon box__img"/>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form__row row"
         v-for="(elem, key, index) in item.lengthVal"
         :key="index + key"
         v-if="currentItem.measure_type === 'LNTH'"
    >
      <div class="col-1">
        <label class="form__label">
          <input type="checkbox" hidden="hidden"
                 class="form__checkbox"
                 :selected="currentItem['l' + (index+1) + '_selected']"
                 v-model="currentItem['l' + (index+1) + '_selected']"
          >
          <span class="form__checkbox_icon"></span>
        </label>
      </div>


      <div class="col-11 col-md-5">
        <label class="form__label">
          <span class="form__labelTitle">Length {{index+1}}</span>
          <Field type="text"
                 class="form__input"
                 :name="`${key}`"
                 :disabled="!currentItem['l' + (index+1) + '_selected']"
                 v-model="currentItem['length' + (index + 1)]"
                 :rules=" currentItem['l' + (index+1) + '_selected'] ? {required: true, numeric: true, max_value: currentItem.limits.max_length, min_value: currentItem.limits.min_length } : ''"
          />
          <span>mm</span>
        </label>
      </div>
      <div class="col-12 col-md-6">
        <ErrorMessage class="form__error form__error_additional"  :name="key" />
      </div>
    </div>



    <div class="d-flex flex-column" v-if="currentItem.measure_type === 'SQR'">

      <div class="form__row order-2 row" :class="{width: isWidth}">
        <div class="col-12 col-md-6">
          <label class="form__label">
            <span class="form__labelTitle">Width</span>
            <Field type="text" class="form__input"
                   name="width"
                   v-model="currentItem.length1"
                   :rules="{required: true, numeric: true, max_value: currentItem.limits.max_width, min_value: currentItem.limits.min_width }"
            />
            <span class="">mm</span>
          </label>
        </div>
        <div class="col-12 col-md-6">
          <ErrorMessage class="form__error form__error_additional"  name="width" />
        </div>
      </div>

      <div class="form__row order-2 row" :class="{drop: !isWidth}">
        <div class="col-12 col-md-6">
          <label class="form__label">
            <span class="form__labelTitle">Drop</span>
            <Field type="text" class="form__input"
                   name="drop"
                   v-model="currentItem.length2"
                   :rules="{required: true, numeric: true, max_value: currentItem.limits.max_length, min_value: currentItem.limits.min_length }"
            />
            <span class="">mm</span>
          </label>
        </div>
        <div class="col-12 col-md-6">
          <ErrorMessage class="form__error form__error_additional"  name="drop" />
        </div>
      </div>

    </div>



    <ul>
      <li v-if="customError" class="form__error">
        {{customError}}
      </li>
    </ul>

    <transition name="fade" mode="out-in">
      <select-colors v-show="selectColours"
                     @onSelect="setColour"></select-colors>
    </transition>

    <template v-if="actionLinksPortalTarget">
      <portal :to="actionLinksPortalTarget">
        <button class="btn btn_transparent"
                form="test"
                type="submit"
        ><svg-icon name="solid/floppy-disk"></svg-icon> Save
        </button>
      </portal>
    </template>
    <template v-else>
      <button class="btn btn_default"
              form="test"
              type="submit"
      ><svg-icon name="solid/floppy-disk"></svg-icon> Save
      </button>
    </template>
</Form>

  </div>
</template>

<script>
  import {mapState, mapGetters} from 'vuex';
  import {httpReq} from "../../../htttpReq";
  import store from "@/store/store";
  import {defineAsyncComponent} from "vue";
  const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

  export default {
    name: "AdditionalAccessoriesSize",

    data() {
      return {
          isWidth: false,
        showMitreCut: false,
        customError: false,
        validForm: false,
        item: {
            qty: 1,
          lengthVal: {
            length1: '',
            length2: '',
            length3: '',
            length4: '',
          },
        },
      }
    },

      components: {
          SelectColors,
      },

    props: ['mitreCut', 'accessory', 'measureType', 'order', 'selectIt', 'styleId', 'actionLinksPortalTarget'],

    methods: {
      ucFirst(str) {
        if (!str) return str;
        if (!isNaN(parseInt(str.slice(str.length - 1)))) {
          return str[0].toUpperCase() + str.slice(1, str.length - 1) + ' ' + str.slice(str.length - 1);
        }
        else {
          return str[0].toUpperCase() + str.slice(1);
        }
      },

      save() {
       /* this.$validator.validate().then(valid => {
          if (valid && this.isFormValid) {*/
          if(this.isFormValid)  this.$store.dispatch('orderPreview/saveAccessory');
        /*  }
        });*/
      },

      back() {
        this.$store.commit('orderPreview/additionalAccessory', {item: 0, qty: 1, length1: 0, length2: 0, length3: 0, length4: 0});
      },

      isError() {
        if (!this.validForm) {
          this.validForm = true;
          this.customError = false;
        }
        return this.validForm;
      },

      openColourSelection(){

          if (!this.currentItem.select_colour)
              return;

          if (this.currentItem.colour_group)
              this.$store.commit('optionColours/colourGroup', this.currentItem.colour_group);

          this.$store.commit('optionColours/openModal', true);
          this.$store.commit('optionColours/getColors');
      },

      setColour(c){
          //let c = this.$store.getters['optionColours/selectedColour'];
          if (c && c.colour) {

            this.$store.commit('orderPreview/checkAdditionalAccessory', {
              colour: c.colour
            })
          }
      },

    },

    computed: {
      ...mapGetters({
        editAccessoryItem: 'accessories/editAccessoryItem',
        editAccessory: 'orderPreview/editAccessory',
        currentItem: 'orderPreview/additionalAccessory',
          selectColours: 'optionColours/openModal',
      }),

      isFormValid() {

        this.validForm = this.currentItem.qty > 0;

        if (this.currentItem.measure_type == 'LNTH')
        {
            this.validForm &= (this.currentItem.length1 > 0
                || this.currentItem.length2 > 0
                || this.currentItem.length3 > 0
                || this.currentItem.length4 > 0)
        }
        else if (this.currentItem.measure_type == 'SQR')
        {
            this.validForm &= (this.currentItem.length1 > 0
                && this.currentItem.length2 > 0)

        }
          this.customError = !this.validForm;

        if (!this.validForm) {
          this.customError = 'Please select at least one not empty value for Length';
        }
        return this.validForm;
      },

    },

        created(){

            httpReq.get('user/profile').then(response => {
                if (response.data.prefer_entry === 'width') {
                    this.isWidth = true;
                }
            });
            this.currentItem.l1_selected = this.currentItem.length1 > 0;
            this.currentItem.l2_selected = this.currentItem.length2 > 0;
            this.currentItem.l3_selected = this.currentItem.length3 > 0;
            this.currentItem.l4_selected = this.currentItem.length4 > 0;
        },



  }
</script>

<style scoped lang="scss">
  @import '../../../assets/scss/utils/vars';

  .form {

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
    }

    &__labelTitle {
      color: $navy;
      width: calc(30% - 20px);
      min-width: 120px;
    }

    &__error {
      position: absolute;
      bottom: -10px
    }

    &__label {
      display: flex;
      align-items: center;
      flex: 1 1;
      margin: 15px 5px;
      width: auto;
    }

    &__row {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 10px;
      .form__label {
        &:nth-child(1) {
          flex: 2 1;
        }
        &:nth-child(2) {
          max-width: 100px;
          margin-right: 20px;
        }
      }
    }
  }

  .btn {
    margin: 20px 0 0;
    max-width: none;
  }

  .modal__close {
    color: $green;
    top: 0.7rem;
    z-index: 999;
  }

  .form__error {
    position: static;
  }

  .form__error.form__error_additional {
    position: absolute;
  }

  .thumbnailsTable__text {
    padding: 10px 0;
  }
</style>
